const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://w6b7sqbhvb.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://89osel4l1d.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.nsr.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.nsr.forwoodsafety.com',
    WEBSOCKET: 'wss://31o1517wr7.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
};

export default config;